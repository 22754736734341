import {Box, Button, Card, CardActions, CardContent, IconButton, Typography} from "@mui/joy";
import {KeyboardArrowRight, ExpandMore as ExpandMoreIcon} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {Collapse} from "@mui/material";
import {useState} from "react";
import {styled} from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ServiceCard({service, salon, readonly = false, expandable = false, expanded = false}) {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(expanded);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card>
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
        <Typography level="title-lg">{service.title}</Typography>
        {expandable && (
          <ExpandMore
            expand={isExpanded}
            onClick={handleExpandClick}
            aria-expanded={isExpanded}
            aria-label="show more"
            style={{minHeight: '25px'}}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
      </Box>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent orientation="horizontal">
          <div>
            <Typography level="body-sm">{service.description}</Typography>
          </div>
        </CardContent>
        <CardActions orientation="horizontal">
          <Typography fontSize="lg" fontWeight="lg">
            €{service.price}
          </Typography>
          {!readonly && (
            <Button
              variant="solid"
              size="md"
              color="primary"
              aria-label=""
              sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
              style={{'backgroundColor': salon.primaryColor}}
              endDecorator={<KeyboardArrowRight />}
              onClick={() => {
                navigate(`date-time?serviceId=${service.id}`);
              }}
            >
              Rezervuoti
            </Button>
          )}
        </CardActions>
      </Collapse>
    </Card>
  );
}