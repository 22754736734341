import React from 'react'
import ReactDOM from 'react-dom/client'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/inter';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import SalonBookingCompleted from "./routes/salon-booking-completed.jsx";
import AdminBookingReview, {loader as bookingUuidLoader} from "./routes/admin-booking-review.jsx";
import ComingSoon from "./routes/coming-soon.jsx";
import SalonBookingServices from "./routes/salon-booking-services.jsx";
import SalonBookingDatetime from "./routes/salon-booking-datetime.jsx";
import SalonBookingContacts from "./routes/salon-booking-contacts";
import SalonBooking, {loader as salonRootLoader} from "./routes/salon-booking";
import { Navigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import * as ReactGA from "react-ga";

ReactGA.initialize("G-9SLN8QMXVL");

Sentry.init({
  dsn: "https://ecfa9a4d3f0207a70c224d14a2da443c@o4506531670327296.ingest.sentry.io/4506531675635712",
  integrations: [],
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <ComingSoon />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/ruta-glow",
    element: <Navigate to="/s/ruta-glow" />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/donny-glow",
    element: <Navigate to="/s/donny-glow" />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/s/:salonUrl",
    element: <SalonBooking />,
    errorElement: <ErrorPage />,
    loader: salonRootLoader,
    children: [
      {
        index: true,
        element: <SalonBookingServices />,
      },
      {
        path: "/s/:salonUrl/date-time",
        element: <SalonBookingDatetime />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/s/:salonUrl/booking-contacts",
        element: <SalonBookingContacts />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/s/:salonUrl/booking-completed",
        element: <SalonBookingCompleted />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/booking-review/:uuid",
    element: <AdminBookingReview />,
    errorElement: <ErrorPage />,
    loader: bookingUuidLoader,
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  </React.StrictMode>,
)
