import {Outlet, useLoaderData, useLocation} from "react-router-dom";
import {createContext, useEffect} from "react";
import {getSalonByUrl, getSalonServices} from "../api.js";
import {SalonCard} from "../components/salon-card.jsx";
import {Card} from "@mui/joy";
import {ga} from "react-ga";

export const SalonContext = createContext();
export const SalonServicesContext = createContext();

export async function loader({ params }) {
  const salon = await getSalonByUrl(params.salonUrl);
  const services = await getSalonServices(salon.id)
  return { salon, services };
}

export default function SalonBooking() {
  const { services, salon } = useLoaderData();
  let location = useLocation();

  useEffect(() => {
    ga('send', 'pageview');
    console.log('send pageview');
  }, [location]);

  return (
    <SalonContext.Provider value={salon}>
      <SalonServicesContext.Provider value={services}>
        <Card variant="plain">
          <SalonCard salon={salon} />
          <Outlet />
        </Card>
      </SalonServicesContext.Provider>
    </SalonContext.Provider>
  );
}