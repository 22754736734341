import {createClient} from "@supabase/supabase-js";
import moment from "moment";

const supabase = createClient("https://locuvvyejbwemziuvjol.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxvY3V2dnllamJ3ZW16aXV2am9sIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM2OTg5MTcsImV4cCI6MjAxOTI3NDkxN30.WfI9axh_z14fW9P1ppBjv8Ss0_K9YgVn8oisumLn_c4");

export async function getSalonByUrl(url) {
  const { data } = await supabase.from("salons").select().eq("url", url).single();
  return data;
}

export async function getSalonById(salonId) {
  const { data } = await supabase.from("salons").select().eq("id", salonId).single();
  return data;
}

export async function getTimeSlotsForSalonForMonth(salonId, date) {
  const startDate = date.startOf('month').format('YYYY-MM-DD');
  const endDate = date.endOf('month').format('YYYY-MM-DD');

  const { data } = await supabase.from("time_slots")
    .select()
    .eq("salonId", salonId)
    .gte("date", startDate)
    .lte("date", endDate);

  return data;
}

export async function getHolidayDates(salonId, dateRange) {
  const { data } = await supabase.from("salon_holidays")
    .select('date')
    .eq("salonId", salonId)
    .gt("date", moment().format("YYYY-MM-DD"))
    .lte("date", moment().add(dateRange, 'days').format("YYYY-MM-DD"));

  return data;
}

export async function getBookedSlots(salonId, dateRange) {
  const { data, error } = await supabase.functions.invoke('booked-slots', {
    body: JSON.stringify({
      salonId,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(dateRange, 'days').format("YYYY-MM-DD"),
    })
  });

  if (error) throw new Error(error.message);
  return data;
}

export async function createBooking({salonId, serviceId, date, startTime, name, phone, email, comment}) {
  const { data, error } = await supabase.rpc('create_booking', {
    in_salon_id: salonId,
    in_service_id: serviceId,
    in_date: date,
    in_start_time: startTime,
    in_name: name,
    in_phone: phone,
    in_email: email,
    in_comment: comment,
  });
  return { data, error };
}

export async function approveBooking(uuid) {
  const { data, error } = await supabase.rpc('approve_booking', {
    in_uuid: uuid,
  });
  if (data !== 0) throw new Error("Approval failed")
  if (error) throw new Error(error.message)
}

export async function cancelBooking(uuid, message) {
  const { data, error } = await supabase.rpc('admin_cancel_booking', {
    in_uuid: uuid,
    in_cancel_comment: message
  });
  return { data, error };
  // if (data !== 0) throw new Error("Cancellation failed")
  // if (error) throw new Error(error.message)
}

export async function getBookingByUuid(uuid) {
  const { data, error } = await supabase.functions.invoke('booking-by-uuid', {
    body: JSON.stringify({ uuid })
  });

  if (error) throw new Error(error.message);
  return data;
}

export async function subscribeEmail(email) {
  const { error } = await supabase
    .from('newsletter')
    .insert({ email });

  if (error) throw new Error(error.message)
}

export async function getSalonServices(salonId) {
  const { data, error } = await supabase.from("salon_services")
    .select()
    .eq("salonId", salonId)
    .order('sortOrder');

  if (error) throw new Error(error.message)
  return data;
}

export async function getSalonService(serviceId) {
  const { data, error } = await supabase.from("salon_services")
    .select()
    .eq('id', serviceId)
    .single();

  if (error) throw new Error(error.message)
  return data;
}

