import {
  Alert, Box, Button, FormControl, FormHelperText,
  Grid, IconButton, Input, Sheet, Stack, Step, StepIndicator, Stepper, Typography
} from '@mui/joy';
import {DateCalendar} from "@mui/x-date-pickers";
import moment from "moment";
import {styled} from '@mui/joy/styles';
import {CloseRounded, InfoOutlined, KeyboardArrowRight, Report} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import './ruta-glow.css';
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {createBooking} from "../api.js";
import {SalonCard} from "../components/salon-card.jsx";

const primaryColor = '#bc6c25';

const timeSlots = [
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
]

const Item = styled(Sheet)(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
  ...theme.typography['body-sm'],
  padding: theme.spacing(0.5),
  textAlign: 'center',
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

export default function RutaGlow() {
  const navigate = useNavigate();
  const [showTimes, setShowTimes] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [errorMessage, setErrorMessage] = useState('');

  // const [selectedMonth, setSelectedMonth] = useState(null);
  // const [timeSlotsLoaded, setTimeSlotsLoaded] = useState(false);
  // const [timeSlotMonths, setTimeSlotMonths] = useState(new Set());
  // const [timeSlots, setTimeSlots] = useState(new Map());
  // const [timeSlotDates, setTimeSlotDates] = useState(new Set());

  useEffect(() => {
    if (selectedDate) {
      setShowTimes(true);
      setSelectedTime(null);
      setShowContactForm(false);
      scrollToStep("2");
    }

  }, [selectedDate]);

  // useEffect(() => {
  //   console.log('selectedMonth effect');
  //   console.log(selectedMonth);
  //   const newMonth = selectedMonth || moment();
  //
  //   if (!timeSlotMonths.has(newMonth.format('YYYY-MM'))) {
  //     fetchTimeSlots(newMonth);
  //   }
  //
  // }, [selectedMonth]);

  // async function fetchTimeSlots(newDate) {
  //   console.log('fetching....')
  //   setTimeSlotsLoaded(false);
  //   const response = await getTimeSlotsForSalonForMonth(1, newDate);
  //   console.log('response', response);
  //   if (!response || response.length < 1) {
  //     setTimeSlotsLoaded(true);
  //     return;
  //   }
  //
  //   const newDates = new Set(response.map(x => x.date));
  //   const newTimeSlots = new Map();
  //   newDates.forEach(d => {
  //     const dSlots = response
  //       .filter(x => x.date === d)
  //       .map(x => ({
  //         'startTime': x.startTime,
  //         'enabled': x.enabled,
  //       }));
  //     newTimeSlots.set(d, dSlots);
  //   })
  //
  //   setTimeSlots(new Map([...timeSlots, ...newTimeSlots]));
  //   setTimeSlotMonths(new Set([...timeSlotMonths, newDate.format('YYYY-MM')]));
  //   setTimeSlotDates(new Set([...timeSlotDates, ...newDates]));
  //   if (!selectedDate) {
  //     // console.log('newDates.keys().next()', newDates.keys().next().value);
  //     setSelectedDate(moment(newDates.keys().next().value));
  //   }
  //   setTimeSlotsLoaded(true);
  // }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Privalomas'),
      phone: Yup.string()
        .required('Privalomas'),
      email: Yup.string()
        .email('Neteisingas el. pašto adresas')
        .required('Privalomas'),
    }),
    onSubmit: async (values) =>  {
      const bookingPayload = {
        ...values,
        salonId: 1,
        date: selectedDate.format("YYYY-MM-DD"),
        startTime: selectedTime,
      };
      const {data, error} = await createBooking(bookingPayload);

      if (error) {
        if (error.code && error.code === 'P0001') {
          setErrorMessage(error.message)
          return;
        }
        setErrorMessage("Įvyko klaida")
      } else {
        navigate('/booking-completed?' + (new URLSearchParams(bookingPayload)).toString());
      }
    },
  });

  const scrollToStep = (stepNo) => {
    setTimeout(() => {
      const element = document.getElementById('step-' + stepNo);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }, 300);
  };

  // if (!timeSlotsLoaded) {
  //   return (
  //     <>
  //       <HeaderCard/>
  //       Loading....
  //     </>
  //   );
  // }

  return (
    <>
      <SalonCard />
      <ProgressStep number="1" text="Pasirinkite datą"/>

      <DateCalendar
        views={['day']}
        minDate={moment().add(1, 'days')}
        maxDate={moment().add(120, 'days')}
        // shouldDisableDate={(date) => {
        //   return !timeSlotDates.has(date.format("YYYY-MM-DD"));
        // }}
        // onMonthChange={(newMonth) => {
        //   setSelectedMonth(newMonth)
        //   console.log('newMonth', newMonth);
        // }}
        onChange={(newValue) => {
          if (!newValue.isSame(selectedDate)) {
            setSelectedDate(newValue);
            // setShowTimes(true);
            // setSelectedTime(null);
            // setShowContactForm(false);
            // scrollToStep("2");
          }
        }}
        value={selectedDate}
      />

      {showTimes && (
        <>
          <ProgressStep number="2" text="Pasirinkite laiką"/>
          <Grid sx={{flexGrow: 1, my: 2}} container>
            <Grid xs={12}>
              <Grid container justifyContent="center">
                {timeSlots.map(x => {
                      const isSelected = x === selectedTime;
                      const startTime = x;

                      const styles = isSelected ? {
                        'width': '70px',
                        'backgroundColor': primaryColor,
                      } : {
                        'width': '70px',
                        'color': primaryColor,
                        'borderColor': primaryColor,
                      }
                      return (
                        <Grid xs={3} key={startTime}>
                          <Item>
                            <Button
                              size="md"
                              variant={isSelected ? 'solid' : 'outlined'}
                              style={styles}
                              value={startTime}
                              onClick={(e) => {
                                setSelectedTime(e.target.value);
                                setShowContactForm(true);
                                scrollToStep("3");
                              }}
                            >
                              {startTime}
                            </Button>
                          </Item>
                        </Grid>
                      )
                    }
                  )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {showContactForm && (
        <>
          <ProgressStep number="3" text="Kontaktinė informacija"/>
          <SignupForm formik={formik} errorMessage={errorMessage} onErrorMessageClose={() => setErrorMessage('')} />
        </>
      )}
      <div id="bottom"></div>
    </>
  );
}

const SignupForm = ({formik, errorMessage, onErrorMessageClose}) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3} sx={{mt: 2}}>
        <FormControl error={formik.touched.name && !!formik.errors.name}>
          <Input
            placeholder="Vardas..."
            name="name"
            type="text"
            {...formik.getFieldProps('name')}
          />
          {formik.touched.name && formik.errors.name ? (
            <FormHelperText>
              <InfoOutlined/>
              {formik.errors.name}
            </FormHelperText>
          ) : null}
        </FormControl>

        <FormControl error={formik.touched.phone && !!formik.errors.phone}>
          <Input
            placeholder="Tel. Numeris..."
            name="phone"
            type="text"
            {...formik.getFieldProps('phone')}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <FormHelperText>
              <InfoOutlined/>
              {formik.errors.phone}
            </FormHelperText>
          ) : (
            <FormHelperText style={{fontSize: 'small'}}>Jei reikėtų susisiekti</FormHelperText>
          )}
        </FormControl>

        <FormControl error={formik.touched.email && !!formik.errors.email}>
          <Input
            placeholder="El. Paštas..."
            name="email"
            type="text"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <FormHelperText>
              <InfoOutlined/>
              {formik.errors.email}
            </FormHelperText>
          ) : (
            <FormHelperText style={{fontSize: 'small'}}>Gausi daugiau info ir priminimą</FormHelperText>
          )}
        </FormControl>

        {!!errorMessage && (
          <ErrorAlert title="Klaida. Bandykite dar kartą" text={errorMessage} onClose={onErrorMessageClose} />
        )}

        <Button
          endDecorator={<KeyboardArrowRight/>}
          color="success"
          type="submit"
        >
          Rezervuoti
        </Button>
      </Stack>
    </form>
  );
};

function ProgressStep({number, text}) {
  return (
    <Stepper size="lg" id={'step-' + number}>
      <Step
        indicator={
          <StepIndicator variant="solid" color="success">{number}</StepIndicator>
        }
      >{text}</Step>
    </Stepper>
  );
}

export function ErrorAlert({text, title, onClose}) {
  return (
    <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
      <Alert
        sx={{ alignItems: 'flex-start' }}
        startDecorator={<Report />}
        variant="soft"
        color="danger"
        endDecorator={
          <IconButton variant="soft" color="danger" onClick={onClose}>
            <CloseRounded />
          </IconButton>
        }
      >
        <div>
          <div>{title}</div>
          <Typography level="body-sm" color="danger">
            {text}
          </Typography>
        </div>
      </Alert>
    </Box>

  );
}