import {approveBooking, cancelBooking, getBookingByUuid, getSalonById, getSalonService} from "../api.js";
import {useLoaderData} from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, FormControl, FormHelperText,
  Modal,
  ModalDialog,
  Textarea
} from "@mui/joy";
import {useEffect, useState} from "react";
import {SalonCard} from "../components/salon-card";
import Typography from "@mui/joy/Typography";
import ServiceCard from "../components/service-card.jsx";
import {InfoOutlined, WarningRounded} from "@mui/icons-material";
import {useFormik} from "formik";
import * as Yup from "yup";
import * as bookingStatus from "../helpers/booking-status";
import {ga} from "react-ga";

export async function loader({ params }) {
  const booking = await getBookingByUuid(params.uuid);
  const salon = await getSalonById(booking.salonId);
  const service = await getSalonService(booking.serviceId);
  return { salon, booking, service };
}

function statusToLtText(status) {
  switch (status) {
    case bookingStatus.PENDING_REVIEW:
      return "Nauja rezervacija";
    case bookingStatus.CONFIRMED:
      return "Rezervacija patvirtinta";
    case bookingStatus.CANCELLED:
      return "Rezervacija atšaukta";
    default:
      return status;
  }
}

export default function AdminBookingReview() {
  useEffect(() => {
    ga('send', 'pageview');
    console.log('send pageview');
  }, [location]);

  const { booking, service, salon } = useLoaderData();
  if (!booking) throw new Error("Not Found")
  if (!service) throw new Error("Not Found")
  if (!salon) throw new Error("Not Found")

  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const cancelFormFormik = useFormik({
    initialValues: {
      cancelComment: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      cancelComment: Yup.string()
        .required('Privalomas'),
    }),
    onSubmit: async (values) =>  {
      console.log('onsubit?')
      const { data, error } = await cancelBooking(booking.uuid, values.cancelComment);
      console.log('err', error);

      if (error) {
        if (error.code && error.code === 'P0001') {
          setErrorMsg(error.message)
          return;
        }
        setErrorMsg("Įvyko klaida, bandykite dar kartą")
      } else {
        setErrorMsg('');
        setSuccessMsg("Rezervacija atšaukta");
        setOpenCancelModal(false);
      }
    },
  });

  return (
    <Card variant="outlined">
      <SalonCard salon={salon} />
      <ServiceCard
        service={service}
        expanded
        readonly
      />

      <Typography level="h1" color="success">{statusToLtText(booking.status)}</Typography>
      <Typography level="h2" fontSize="xl" sx={{ mb: 0.5 }} >
        {booking.date} {booking.displayStartTime}
      </Typography>
      <Typography level="body-sm">
        {booking.name} - {booking.phone} - {booking.email}
      </Typography>
      {!!booking.comment && (
        <Typography level="body-sm">
          Komentaras:<br />
          {booking.comment}
        </Typography>
      )}
      <Button
        disabled={booking.status === bookingStatus.CONFIRMED}
        onClick={async () => {
          setSuccessMsg('');
          setErrorMsg('');
          try {
            await approveBooking(booking.uuid);
            setSuccessMsg('Patvirtinta');
          } catch (e) {
            setErrorMsg('Nepavyko patvirtinti. Bankytite dar kartą');
          }
        }}
      >
        Patvirtinti
      </Button>
      <Button
        disabled={booking.status === bookingStatus.CANCELLED}
        color="danger"
        variant="outlined"
        onClick={async () => {
          setOpenCancelModal(true);
          setSuccessMsg('');
          setErrorMsg('');
        }}
      >
        Atšaukti rezervaciją
      </Button>

      {!!successMsg && (
        <Alert variant="outlined" color="success">
          {successMsg}
        </Alert>
      )}
      {!!errorMsg && (
        <Alert variant="outlined" color="danger">
          {errorMsg}
        </Alert>
      )}

      <Modal open={openCancelModal} onClose={() => setOpenCancelModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            Patvirtinti atšaukimą
          </DialogTitle>
          <Divider />
          <DialogContent>
            Nurodyti priežastį kodėl atšaukiama. Žinutė bus persiųsta klientui.
            <form onSubmit={cancelFormFormik.handleSubmit}
            >
              <FormControl error={cancelFormFormik.touched.cancelComment && !!cancelFormFormik.errors.cancelComment}>
                <Textarea
                  placeholder="Komentaras*"
                  minRows="4"
                  name="cancelComment"
                  {...cancelFormFormik.getFieldProps('cancelComment')}
                />
                {cancelFormFormik.touched.cancelComment && cancelFormFormik.errors.cancelComment ? (
                  <FormHelperText>
                    <InfoOutlined/>
                    {cancelFormFormik.errors.cancelComment}
                  </FormHelperText>
                ) : null}
              </FormControl>

              {!!errorMsg && (
                <Alert variant="outlined" color="danger" sx={{mt: 2}}>
                  {errorMsg}
                </Alert>
              )}

              <DialogActions>
                <Button variant="solid" color="danger" type="submit">
                  Atšaukti rezervaciją
                </Button>
                <Button variant="plain" color="neutral" onClick={() => setOpenCancelModal(false)}>
                  Atgal
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </Card>
  );
}

