import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Sheet,
  Step,
  StepIndicator,
  Stepper
} from "@mui/joy";
import {DateCalendar} from "@mui/x-date-pickers";
import moment from "moment/moment.js";
import {useContext, useEffect, useState} from "react";
import {styled} from "@mui/joy/styles";
import {Close, KeyboardArrowRight, Warning} from "@mui/icons-material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SalonContext, SalonServicesContext} from "./salon-booking.jsx";
import ServiceCard from "../components/service-card.jsx";
import {getBookedSlots, getHolidayDates} from "../api.js";

const timeSlots = [
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
]

const Item = styled(Sheet)(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
  ...theme.typography['body-sm'],
  padding: theme.spacing(0.5),
  textAlign: 'center',
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

const calculateNeededSlots = (date, startTime, durationMins, prepMins) => {
  const timeParts = startTime.split(':');
  let currentDt = date.clone().hours(timeParts[0]).minutes(timeParts[1]).seconds(0);
  let startDt = currentDt.clone().subtract(prepMins, 'minutes');
  const endDt = currentDt.clone().add(durationMins, 'minutes');
  const result = [startDt.format('HH:mm')];
  for (let i = 0; i < 20; i++) {
    startDt = startDt.add(30, 'minutes');
    if (startDt.isBefore(endDt)) {
      result.push(startDt.format('HH:mm'));
    } else {
      return result;
    }
  }
  return result;
}

const allNeededSlotsAvailable = (neededSlots, bookedSlots) => {
  for (const neededSlot of neededSlots) {
    if (bookedSlots.has(neededSlot)) {
      return false;
    }
  }
  return true;
}

export default function SalonBookingDatetime() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const salon = useContext(SalonContext);
  const services = useContext(SalonServicesContext);
  const [selectedService, setSelectedService] = useState(null);

  const [holidayDatesLoaded, setHolidayDatesLoaded] = useState(false);
  const [holidayDates, setHolidayDates] = useState(new Set());
  const [bookedSlotsLoaded, setBookedSlotsLoaded] = useState(false);
  const [bookedSlots, setBookedSlots] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateBookedSlots, setSelectedDateBookedSlots] = useState(new Set());

  const [selectedTime, setSelectedTime] = useState(null);
  const [showTimes, setShowTimes] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // preselect date and time from url
  useEffect(() => {
    if (searchParams.has('date')) {
      const date = moment(searchParams.get('date'));
      updateSelectedDateBookedSlots(date, bookedSlots);
      setSelectedDate(date);
    }
    if (searchParams.has('startTime')) {
      setShowTimes(true);
      setSelectedTime(searchParams.get('startTime'));
    }

  }, [searchParams]);

  // load holiday dates
  useEffect(() => {
    async function resolveHolidays() {
      const holidays = await getHolidayDates(salon.id, 120);
      if (holidays.length > 0) {
        setHolidayDates(new Set(holidays.map(x => x.date)));
      }
      setHolidayDatesLoaded(true);
    }
    resolveHolidays();
  }, [searchParams]);

  // load booked slots
  useEffect(() => {
    async function resolveBookedSlots() {
      const data = await getBookedSlots(salon.id, 120);
      if (data.length > 0) {
        setBookedSlots(data);
      }
      setBookedSlotsLoaded(true);
    }
    resolveBookedSlots();
  }, [searchParams]);

  const updateSelectedDateBookedSlots = (date, allBookedSlots) => {
    if (date && allBookedSlots.length > 0) {
      const selectedDateStr = date.format('YYYY-MM-DD');
      const result = allBookedSlots.filter(x => selectedDateStr === x.date)
        .map(x => x.startTime);
      const resultWithLeadingZeros = result.filter(x => x.length === 4)
        .map(x => '0' + x);
      setSelectedDateBookedSlots(new Set([
        ...result,
        ...resultWithLeadingZeros
      ]));
    }
  }

  // resolve selected service
  useEffect(() => {
    if (services && searchParams.has('serviceId')) {
      const serviceIdUrl = parseInt(searchParams.get('serviceId'));
      const s = services.find(x => x.id === serviceIdUrl);
      setSelectedService(s);
    }
  }, [services, searchParams]);

  if (!holidayDatesLoaded || !bookedSlotsLoaded) {
    return (
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 5 }}>
        <CircularProgress size="lg" color="warning" />
      </Box>
    );
  }

  return (
    <>
      {!!selectedService && (
        <ServiceCard
          service={selectedService}
          expanded={false}
          expandable
          readonly
        />
      )}
      <ProgressStep number="1" text="Data"/>

      <DateCalendar
        views={['day']}
        minDate={moment().add(1, 'days')}
        maxDate={moment('20240701', 'YYYYMMDD')}
        shouldDisableDate={(date) => {
          return holidayDates.has(date.format("YYYY-MM-DD"));
        }}
        onChange={(newValue) => {
          if (!newValue.isSame(selectedDate)) {
            updateSelectedDateBookedSlots(newValue, bookedSlots);
            setSelectedDate(newValue);
            setSelectedTime(null);
            setShowTimes(true);
            setErrorMessage('');
          }
        }}
        value={selectedDate}
      />

      {showTimes && (
        <>
          <ProgressStep number="2" text="Laikas"/>
          <Grid sx={{flexGrow: 1, my: 2}} container>
            <Grid xs={12}>
              <Grid container justifyContent="center">
                {timeSlots.map(slot => {
                    const isSelected = slot === selectedTime;
                    const startTime = slot;

                    const neededSlots = calculateNeededSlots(selectedDate, startTime, selectedService.bookedDuration, selectedService.prepDuration);
                    const slotCanBeBooked = allNeededSlotsAvailable(neededSlots, selectedDateBookedSlots);

                    let styles;
                    if (isSelected) {
                      styles = {
                        'width': '70px',
                        'backgroundColor': salon.primaryColor,
                      };
                    } else if (!slotCanBeBooked) {
                      styles = {
                        'width': '70px',
                      };
                    } else {
                      styles = {
                        'width': '70px',
                        'color': salon.primaryColor,
                        'borderColor': salon.primaryColor,
                      };
                    }

                    return (
                      <Grid xs={3} key={startTime}>
                        <Item>
                          <Button
                            size="md"
                            variant={isSelected ? 'solid' : 'outlined'}
                            style={styles}
                            value={startTime}
                            disabled={!slotCanBeBooked}
                            onClick={(e) => {
                              setSelectedTime(e.target.value);
                              setErrorMessage('');
                            }}
                          >
                            {startTime}
                          </Button>
                        </Item>
                      </Grid>
                    )
                  }
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {!!errorMessage && (
        <ErrorAlert title="Klaida" text={errorMessage} onClose={() => setErrorMessage('')} />
      )}

      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
        <Button
          color="neutral"
          variant="outlined"
          style={{width: '30%'}}
          onClick={() => {
            navigate(`/s/${salon.url}`);
          }}
        >
          Atgal
        </Button>
        <Button
          endDecorator={<KeyboardArrowRight/>}
          color="success"
          style={{width: '60%'}}
          onClick={() => {
            setErrorMessage('');
            if (!selectedDate) {
              setErrorMessage('Pasirinkite datą');
              return;
            }
            if (!selectedTime) {
              setErrorMessage('Pasirinkite laiką')
              return;
            }
            navigate(`/s/${salon.url}/booking-contacts?` + (new URLSearchParams({
              serviceId: searchParams.get("serviceId"),
              date: selectedDate.format('YYYY-MM-DD'),
              startTime: selectedTime
            })).toString());
          }}
        >
          Rezervuoti
        </Button>
      </Box>
    </>
  );
}

function ErrorAlert({text, onClose}) {
  return (
    <Alert
      startDecorator={<Warning />}
      variant="outlined"
      color="danger"
      endDecorator={
        <>
          <IconButton variant="soft" size="sm" color="danger" onClick={onClose}>
            <Close />
          </IconButton>
        </>
      }
    >
      {text}
    </Alert>
  );
}

export function ProgressStep({number, text}) {
  return (
    <Stepper size="lg" id={'step-' + number}>
      <Step
        indicator={
          <StepIndicator variant="solid" color="success">{number}</StepIndicator>
        }
      >{text}</Step>
    </Stepper>
  );
}
