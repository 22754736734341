import {Avatar, Card, CardContent, CardOverflow, Typography} from "@mui/joy";
import {Link} from "react-router-dom";

export function SalonCard({salon}) {
  return (
    <Card orientation="horizontal" variant="outlined">
      <CardOverflow>
        <Avatar
          alt={salon.name}
          src={salon.imageUrl}
          style={{width: 'var(--Avatar-size, 5rem)', height: 'var(--Avatar-size, 5rem)'}}
          sx={{ml: 2}}
        />
      </CardOverflow>
      <CardContent>
        <Typography level="h2" textColor={salon.primaryColor}>
          <Link to={`/${salon.url}`} style={{color: salon.primaryColor}}>{salon.headTitle}</Link>
        </Typography>
        <Typography level="body-sm">{salon.address}</Typography>
      </CardContent>
    </Card>
  );
}
