import {Card, Typography} from "@mui/joy";

export default function DateTimeCard({date, time}) {
  return (
    <Card>
      <div>
        <Typography level="title-lg">{date} {time}</Typography>
      </div>
    </Card>
  );
}