import ServiceCard from "../components/service-card";
import {useContext} from "react";
import {SalonContext, SalonServicesContext} from "./salon-booking.jsx";

export default function SalonBookingServices() {
  const salon = useContext(SalonContext);
  const services = useContext(SalonServicesContext);

  return (
    <>
      {services.map((service) => (
        <ServiceCard
          key={service.id}
          service={service}
          salon={salon}
          expanded
        />
      ))}
    </>
  );
}