import Typography from '@mui/joy/Typography';
import {useSearchParams} from "react-router-dom";
import {Button, Link} from "@mui/joy";
import {useContext, useEffect, useState} from "react";
import {SalonContext, SalonServicesContext} from "./salon-booking.jsx";
import ServiceCard from "../components/service-card.jsx";

export default function SalonBookingCompleted() {
  const [searchParams] = useSearchParams();
  const salon = useContext(SalonContext);
  const services = useContext(SalonServicesContext);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    if (services && searchParams.has('serviceId')) {
      const serviceIdUrl = parseInt(searchParams.get('serviceId'));
      const s = services.find(x => x.id === serviceIdUrl);
      setSelectedService(s);
    }
  }, [services, searchParams]);

  return (
    <>
      {!!selectedService && (
        <ServiceCard
          service={selectedService}
          expanded
          readonly
        />
      )}

      <Typography level="h1" color="success">Atlikta!</Typography>
      <Typography level="h2" fontSize="xl" sx={{ mb: 0.5 }} >
        {searchParams.get("date")} {searchParams.get("startTime")}
      </Typography>
      <Typography level="body-sm">
        Rezervacija užregistruota. Netrukus peržiūrėsiu ir atsiųsiu patvirtinimą. Tikrink paštą.
      </Typography>
      <Typography level="body-sm">
        Taip pat ten rasi daugiau patarimų kaip pasiruošti procedūrai.
      </Typography>
      <Typography level="body-sm">
        Gali uždaryti šį langą.
      </Typography>

      <Typography  level="body-sm">
        Iki pasimatymo,<br/>
        {salon.ownerName}<br/>

        {!!salon.igAccount && (
          <>
            <Button
              startDecorator={<img alt={salon.headTitle} style={{height: '18px'}} src='/ig-logo.svg' />}
              variant="plain"
              size="sm"
              sx={{pl: 0, py: 0}}
              href={`https://www.instagram.com/${salon.igAccount}`}
              component="a"
            >{salon.headTitle}
            </Button>
            <br />
          </>
        )}

        {!!salon.phone && (
          <>
            <Link href={`tel:${salon.phone}`} variant="plain" sx={{pl: 0, py: 0}}>
              {salon.phone}
            </Link><br/>
          </>
        )}
      </Typography>
    </>
  );
}