import {Box, Button, Card, FormControl, FormHelperText, Input, Stack, Textarea} from "@mui/joy";
import {useFormik} from "formik";
import * as Yup from "yup";
import {createBooking} from "../api.js";
import {InfoOutlined, KeyboardArrowRight} from "@mui/icons-material";
import {ProgressStep} from "./salon-booking-datetime.jsx";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ErrorAlert} from "./ruta-glow.jsx";
import {SalonContext, SalonServicesContext} from "./salon-booking.jsx";
import ServiceCard from "../components/service-card.jsx";
import DateTimeCard from "../components/date-time-card.jsx";

export default function SalonBookingContacts() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');

  const salon = useContext(SalonContext);
  const services = useContext(SalonServicesContext);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    if (services && searchParams.has('serviceId')) {
      const serviceIdUrl = parseInt(searchParams.get('serviceId'));
      const s = services.find(x => x.id === serviceIdUrl);
      setSelectedService(s);
    }
  }, [services, searchParams]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      comment: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Privalomas'),
      phone: Yup.string()
        .required('Privalomas'),
      email: Yup.string()
        .email('Neteisingas el. pašto adresas')
        .required('Privalomas'),
    }),
    onSubmit: async (values) =>  {
      const bookingPayload = {
        ...values,
        salonId: salon.id,
        serviceId: searchParams.get('serviceId'),
        date: searchParams.get('date'),
        startTime: searchParams.get('startTime'),
      };
      const {data, error} = await createBooking(bookingPayload);

      if (error) {
        if (error.code && error.code === 'P0001') {
          setErrorMessage(error.message)
          return;
        }
        setErrorMessage("Įvyko klaida")
      } else {
        navigate(`/s/${salon.url}/booking-completed?` + (new URLSearchParams(bookingPayload)).toString());
      }
    },
  });

  return (
    <>
      {!!selectedService && (
        <ServiceCard
          service={selectedService}
          expanded={false}
          expandable
          readonly
        />
      )}

      {searchParams.has('date') && searchParams.has('startTime') && (
        <DateTimeCard
          date={searchParams.get('date')}
          time={searchParams.get('startTime')}
        />
      )}

      <ProgressStep number="3" text="Kontaktinė informacija"/>
      <SignupForm
        formik={formik}
        errorMessage={errorMessage}
        onErrorMessageClose={() => setErrorMessage('')}
        navigate={navigate}
        searchParams={searchParams}
        salon={salon}
      />
    </>
  );
}

const SignupForm = ({formik, errorMessage, onErrorMessageClose, navigate, searchParams, salon}) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3} sx={{mt: 2}}>
        <FormControl error={formik.touched.name && !!formik.errors.name}>
          <Input
            placeholder="Vardas..."
            name="name"
            type="text"
            {...formik.getFieldProps('name')}
          />
          {formik.touched.name && formik.errors.name ? (
            <FormHelperText>
              <InfoOutlined/>
              {formik.errors.name}
            </FormHelperText>
          ) : null}
        </FormControl>

        <FormControl error={formik.touched.phone && !!formik.errors.phone}>
          <Input
            placeholder="Tel. Numeris..."
            name="phone"
            type="text"
            {...formik.getFieldProps('phone')}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <FormHelperText>
              <InfoOutlined/>
              {formik.errors.phone}
            </FormHelperText>
          ) : (
            <FormHelperText style={{fontSize: 'small'}}>Jei reikėtų susisiekti</FormHelperText>
          )}
        </FormControl>

        <FormControl error={formik.touched.email && !!formik.errors.email}>
          <Input
            placeholder="El. Paštas..."
            name="email"
            type="text"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <FormHelperText>
              <InfoOutlined/>
              {formik.errors.email}
            </FormHelperText>
          ) : (
            <FormHelperText style={{fontSize: 'small'}}>Gausi daugiau info ir priminimą</FormHelperText>
          )}
        </FormControl>

        <Textarea
          placeholder="Kita informacija ir klausimai"
          minRows={2}
          name="comment"
          {...formik.getFieldProps('comment')}
        />

        {!!errorMessage && (
          <ErrorAlert title="Klaida. Bandykite dar kartą" text={errorMessage} onClose={onErrorMessageClose} />
        )}

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          <Button
            color="neutral"
            variant="outlined"
            style={{width: '30%'}}
            onClick={() => {
              navigate(`/s/${salon.url}/date-time?` + searchParams.toString());
            }}
          >
            Atgal
          </Button>
          <Button
            endDecorator={<KeyboardArrowRight/>}
            color="success"
            style={{width: '60%'}}
            type="submit"
          >
            Rezervuoti
          </Button>
        </Box>

      </Stack>
    </form>
  );
};