import '../index.css';
import {subscribeEmail} from "../api.js";
import {useState} from "react";
import {Alert} from "@mui/joy";

export default function ComingSoon() {
  const [successMsg, setSuccessMsg] = useState('');

  return (
    <div className="relative h-screen w-full flex items-center justify-center bg-cover bg-center text-center px-5"
         style={{backgroundImage: "url(https://images.pexels.com/photos/3785419/pexels-photo-3785419.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=1&amp;w=500)"}}>
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>

      <div className="z-50 flex flex-col justify-center text-white w-full h-screen">
        <span className="text-bold">glowlist.co</span>
        <h1 className="text-5xl">We are <b>Almost</b> there!</h1>
        <p>Stay tuned for something amazing!!!</p>

        <div className="sm:mt-40">
          <form className="w-full max-w-xl mx-auto">
            <div className="flex items-center border-b border-indigo-500 py-2">
              <input
                className="appearance-none bg-transparent border-none w-full mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="email"
                placeholder="Your email" aria-label="Full name"
                id="email"
              />
                <button
                  className="flex-shrink-0 bg-indigo-500 hover:bg-teal-700 border-indigo-500 hover:border-indigo-700 text-sm border-4 text-white py-1 px-2 rounded"
                  type="button"
                  onClick={async () => {
                    const email = document.getElementById('email').value;
                    if (email) {
                      setSuccessMsg('');

                      await subscribeEmail(email);
                      setSuccessMsg('Subscribed!');
                    }
                  }}
                >
                  Subscribe
                </button>
            </div>
          </form>
          {!!successMsg && (
            <Alert variant="solid" color="neutral" sx={{mt: 3}}>
              {successMsg}
            </Alert>
          )}
        </div>
      </div>

    </div>
);
}